
<app-timeline [cities]="cities"></app-timeline>


<div class="container">
    <div class="travel" data-aos="fade-up" data-aos-duration="1000">

        <div class="row justify-content-center">
            <div class="col-sm-10 mb-5">
                <div class="ratio ratio-16x9 mb-3 mb-5">
                    <iframe class="border-radius" [src]="'https://www.youtube.com/embed/doME8hmyfh4' | safe"
                        title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-18.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-13.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-6.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-16.jpg" alt="" class="img-fluid" />
            </div>

        </div>


        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-5.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-14.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-2.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-7.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-9.jpg" alt="" class="img-fluid" />
            </div>
        </div>

    

        <div class="row">
           

            <div class="col-lg-5">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-8.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-15.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-12.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-17.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <div class="row">

          
            <div class="col-lg-5">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-4.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-3.jpg" alt="" class="img-fluid" />
            </div>



        </div>

        <div class="row">
            <div class="col-lg-7">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-11.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-10.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <h3 class="dillan text-center m-5">
            A equipa
        </h3>

        <div class="row profile justify-content-center my-5">
            <div class="col-lg-2 text-center">
                <a target="_blank" href="https://www.instagram.com/nelsonrsousa/">
                    <img src="assets/img/travels/2022/picos/nelson.jpg" alt="" class="img-fluid" />
                </a>       
            </div>

            <div class="col-lg-2 text-center">
                <a target="_blank" href="https://www.instagram.com/brasdio/">
                    <img src="assets/img/travels/2022/picos/bras.jpg" alt="" class="img-fluid" />
                </a>
            </div>

            <div class="col-lg-2  text-center">
                <a target="_blank" href="https://www.instagram.com/r.cortinhas/">
                    <img src="assets/img/travels/2022/picos/cortinhas.jpg" alt="" class="img-fluid" />
                </a>
            </div>

            <div class="col-lg-2  text-center">
                <a target="_blank" href="https://www.instagram.com/manelpessanha/">
                    <img src="assets/img/travels/2022/picos/pessanha.jpg" alt="" class="img-fluid" />
                </a>
            </div>
        </div>
    </div>

</div>