export class Coordinates {
    public static aeroportos = {

        saCarneiro: { nome: "saCarneiro", coordenadas: [41.239883551290404, -8.678898811340334], imagem: null },
        humbertoDelgado: { nome: "humbertoDelgado", coordenadas: [38.724324826655405, -9.355459213256838], imagem: null },
        bucareste: { nome: "bucareste", coordenadas: [44.43602980258919, 26.102271080017093], imagem: null },
        marrakesh: { nome: "marrakesh", coordenadas: [31.62824454442111, -7.985687255859376], imagem: null },
        madrid: { nome: "madrid", coordenadas: [40.4908915315281, -3.573474884033203], imagem: null },
        amam: { nome: "amam", coordenadas: [31.72035543994747, 35.99430084228516], imagem: null },
        roma: { nome: "roma", coordenadas: [41.897044369507256, 12.479438781738283], imagem: null },
        oslo: { nome: "oslo", coordenadas: [60.196134435730265, 11.099367141723635], imagem: null },
        evenes: { nome: "evenes", coordenadas: [68.48770110702972, 16.69458389282227], imagem: null },
        madeira: { nome: "madeira", coordenadas: [32.69699678477289, -16.77543640136719], imagem: null },
        lyon: { nome: "lyon", coordenadas: [45.72748348162605, 4.943075180053712], imagem: null },
        paris: { nome: "paris", coordenadas: [48.852695170596945, 2.347640991210938], imagem: null },
        frankfurt: { nome: "frankfurt", coordenadas: [50.11072628286801, 8.682804107666017], imagem: null },
        varsovia: { nome: "varsovia", coordenadas: [52.1622710502385, 20.97049713134766], imagem: null },
        amesterdao: { nome: "amesterdao", coordenadas: [52.32065178453125, 4.740600585937501], imagem: null },
        genebra: { nome: "genebra", coordenadas: [46.23619953761479, 6.109085083007813], imagem: null },
        munich: { nome: "munich", coordenadas: [48.08295303469022, 11.287422180175781], imagem: null },
        bergamo: { nome: "bergamo", coordenadas: [45.668749942262124, 9.701313972473146], imagem: null },
        malpensa: { nome: "malpensa", coordenadas: [45.62862464083308, 8.723230361938478], imagem: null },
        aqaba: { nome: "aqaba", coordenadas: [29.60607340647818, 35.01926422119141], imagem: null },
        nuremberg: { nome: "nuremberg", coordenadas: [49.498639547249255, 11.077995300292969], imagem: null },
        tirana: { nome: "tirana", coordenadas: [41.41280128616949, 19.720458984375004], imagem: null },
        bari: { nome: "bari", coordenadas: [41.13836264748299, 16.760330200195316], imagem: null }
    };

    public static locais = {
        // Romenia
        bucareste: { nome: "bucareste", coordenadas: [44.43602980258919, 26.102271080017093], imagem: null },
        sinaia: { nome: "sinaia", coordenadas: [45.353029708061236, 25.553319454193115], imagem: null },

        // Espanha
        madrid: { nome: "madrid", coordenadas: [40.4176275, -3.6854657], imagem: null },
        sevilha: { nome: "sevilha", coordenadas: [37.389118995164914, -5.995788574218751], imagem: null },

        // Polonia
        sopot: { nome: "sopot", coordenadas: [54.44594115274813, 18.57018828392029], imagem: null },
        gdansk: { nome: "gdansk", coordenadas: [54.34736096425876, 18.65538597106934], imagem: null },
        lodz: { nome: "lodz", coordenadas: [51.76934645919568, 19.469704627990726], imagem: null },
        varsovia: { nome: "varsovia", coordenadas: [52.24371662767831, 21.047229766845703], imagem: null },

        // Suica
        genebra: { nome: "genebra", coordenadas: [46.20293952440993, 6.144275665283204], imagem: null },
        lausanne: { nome: "lausanne", coordenadas: [46.52185670484264, 6.63402557373047], imagem: null },
        montreux: { nome: "montreux", coordenadas: [46.43084383617054, 6.91391944885254], imagem: null },
        pully: { nome: "pully", coordenadas: [46.50969084099489, 6.661748886108398], imagem: null },

        // Madeira
        fanal: { nome: "fanal", coordenadas: [32.80968993174475, -17.140860557556156], imagem: null },
        arieiro: { nome: "arieiro", coordenadas: [32.73567867993703, -16.928397417068485], imagem: null },
        funchal: { nome: "funchal", coordenadas: [32.64962704223174, -16.90658569335938], imagem: null },
        seixal: { nome: "seixal", coordenadas: [32.82194266943909, -17.103191614151005], imagem: null },
        caldeiraoVerde: { nome: "caldeiraoVerde", coordenadas: [32.78372453535894, -16.905984878540043], imagem: null },

        // Italia
        firenze: { nome: "firenze", coordenadas: [43.76932726207881, 11.25823974609375], imagem: null },
        roma: { nome: "roma", coordenadas: [41.897044369507256, 12.479438781738283], imagem: null },

        // Marrocos
        ourazazete: { nome: "Ourazazete", coordenadas: [30.924239370472744, -6.911087036132813], imagem: null },
        marrakesh: { nome: "marrakesh", coordenadas: [31.62824454442111, -7.985687255859376], imagem: null },
        merouzga: { nome: "Merouzga", coordenadas: [31.10768512847337, -3.99129867553711], imagem: null },
        aintBenAdou: { nome: "AintBenAdou", coordenadas: [31.04764191145905, -7.1299874782562265], imagem: null },
        thingir: { nome: "Thingir", coordenadas: [31.522678254327996, -5.529985427856446], imagem: null },

        // Jordania
        ama: { nome: "ama", coordenadas: [31.95132518161809, 35.924091339111335], imagem: null },
        wadiRum: { nome: "wadiRum", coordenadas: [29.516468863791196, 35.36911010742188], imagem: null },
        aqaba: { nome: "aqaba", coordenadas: [29.52107002584878, 35.00141143798829], imagem: null },
        petra: { nome: "petra", coordenadas: [30.320645, 35.4728554], imagem: null },

        // Gibraltar
        gibraltar: { nome: "gibraltar", coordenadas: [36.13034266047757, -5.346822738647461], imagem: null },

        // França
        chamonix: { nome: "chamonix", coordenadas: [45.924138381648106, 6.872634887695313], imagem: null },
        taize: { nome: "taize", coordenadas: [46.51606648797736, 4.677568674087525], imagem: null },
        paris: { nome: "paris", coordenadas: [48.852695170596945, 2.347640991210938], imagem: null },

        // Portugal
        covaoDaMetade: { nome: "covaoDaMetade", coordenadas: [40.32872633964844, -7.588543295860291], imagem: null },
        serraDoRisco: { nome: "serraDoRisco", coordenadas: [38.45229480752206, -9.022693634033205], imagem: null },
        beja: { nome: "beja", coordenadas: [38.018366061524986, -7.858411073684693], imagem: null },
        evora: { nome: "evora", coordenadas: [38.57120721888756, -7.907238006591798], imagem: null },
        casteloBranco: { nome: "casteloBranco", coordenadas: [39.8261052381889, -7.492675781250001], imagem: null },
        santarem: { nome: "santarem", coordenadas: [39.235743437147484, -8.68623733520508], imagem: null },
        lagos: { nome: "lagos", coordenadas: [37.10234020170541, -8.672032356262209], imagem: null },
        vilaRealSantoAntonio: { nome: "vilaRealSantoAntonio", coordenadas: [37.19456781564642, -7.415567636489869], imagem: null },
        altura: { nome: "altura", coordenadas: [37.17422665280399, -7.499134540557862], imagem: null },
        tomar: { nome: "tomar", coordenadas: [39.60017038261596, -8.413113355636598], imagem: null },

        // Alentejo roadtrip
        reguengosMonsaraz: { nome: "reguengosMonsaraz", coordenadas: [38.42399121302992, -7.534861564636231], imagem: null },
        campinho: { nome: "campinho", coordenadas: [38.35541114496721, -7.4440956115722665], imagem: null },
        mourao: { nome: "mourao", coordenadas: [38.38496945291886, -7.34663486480713], imagem: null },
        monsaraz: { nome: "monsaraz", coordenadas: [38.44345112631421, -7.380656003952027], imagem: null },
        alqueva: { nome: "alqueva", coordenadas: [38.197230683882005, -7.4962806701660165], imagem: null },

        portimao: { nome: "portimao", coordenadas: [37.11647485321271, -8.535346984863283], imagem: null },
        setubal: { nome: "setubal", coordenadas: [38.52424750404186, -8.89047145843506], imagem: null },
        sintra: { nome: "sintra", coordenadas: [38.799232832632825, -9.385457038879396], imagem: null },
        nazare: { nome: "nazare", coordenadas: [39.5966858851616, -9.073140621185305], imagem: null },

        geres: { nome: "geres", coordenadas: [41.67949092154859, -8.174772262573244], imagem: null },
        vouzela: { nome: "vouzela", coordenadas: [40.72266890264228, -8.10919761657715], imagem: null },
        mirazarela: { nome: "mirazarela", coordenadas: [40.862886529031904, -8.282661437988283], imagem: null },

        saoJoaoPesqueira: { nome: "saoJoaoPesqueira", coordenadas: [41.147912670519524, -7.404527664184571], imagem: null },
        pinhao: { nome: "pinhao", coordenadas: [41.190919004685824, -7.545826435089112], imagem: null },
        resende: { nome: "resende", coordenadas: [41.105464216126926, -7.963918447494508], imagem: null },
        fozCoa: { nome: "fozCoa", coordenadas: [41.08197141462944, -7.141628265380859], imagem: null },
        leiria: { nome: "leiria", coordenadas: [39.74728070939316, -8.809425830841066], imagem: null },
        
        sines: { nome: "sines", coordenadas: [37.95634630180267, -8.869056701660158], imagem: null },
        milfontes: { nome: "milfontes", coordenadas: [37.72547278308249, -8.781745433807375], imagem: null },
        zambujeira: { nome: "zambujeira", coordenadas: [37.524341451552, -8.77613425254822], imagem: null },
        odeceixe: { nome: "odeceixe", coordenadas: [37.44202274650925, -8.79683017730713], imagem: null },
        odemira: { nome: "odemira", coordenadas: [37.59785683000378, -8.64278554916382], imagem: null },
        aljezur: { nome: "aljezur", coordenadas: [37.315004339709816, -8.803997039794924], imagem: null },
        sagres: { nome: "sagres", coordenadas: [37.00771908609889, -8.944759368896486], imagem: null },

        almeria: { nome: "almeria", coordenadas: [36.827149555188285, -2.4519252777099614], imagem: null },
        malaga: { nome: "malaga", coordenadas: [36.72113628411141, -4.421975612640382], imagem: null },
        marbella: { nome: "marbella", coordenadas: [36.50683350970207, -4.883862733840943], imagem: null },
        cadiz: { nome: "cadiz", coordenadas: [36.53327783982478, -6.299135684967042], imagem: null }


    };

    //  locais inseridos em rotas

    public static lofoten = {
        // lofoten
        a: { nome: "a", coordenadas: [67.87938776747117, 12.97762155532837], imagem: null },
        reine: { nome: "reine", coordenadas: [67.93244546985456, 13.088922500610353], imagem: null },
        leknes: { nome: "leknes", coordenadas: [68.14739930347332, 13.611330986022951], imagem: null },
        link: { nome: "link", coordenadas: [68.33875239319418, 14.110060930252077], imagem: null },
        hesten: { nome: "hesten", coordenadas: [69.5137105140177, 17.584648132324222], imagem: null },
        sacrisoy: { nome: "sacrisoy", coordenadas: [67.94148080742586, 13.111823201179506], imagem: null },
        campingSegla: { nome: "campingSegla", coordenadas: [69.19804096074814, 17.458434104919437], imagem: null },
    }


    public static alpes = {
        // alpes
        lagoDiComo: { nome: "lagoDiComo", coordenadas: [46.0017315150253, 9.295806884765627], imagem: null },
        bolzano: { nome: "bolzano", coordenadas: [46.48893804576338, 11.3214111328125], imagem: null },
        santaMadalena: { nome: "santaMadalena", coordenadas: [46.64154039229512, 11.71623229980469], imagem: null },
        alpiDiSusi: { nome: "alpiDiSusi", coordenadas: [46.540171, 11.618115], imagem: null },
        seceda: { nome: "seceda", coordenadas: [46.5764597, 11.6722823], imagem: null },
        passoGiau: { nome: "passoGiau", coordenadas: [46.4828073, 12.0508394], imagem: null },
        lagoDiMisurina: { nome: "lagoDiMisurina", coordenadas: [46.58201990644752,12.252116203308105], imagem: null },
        cortinaDampezo: { nome: "lagoDiMisurina", coordenadas: [46.53867240778334,12.137918472290039], imagem: null },
        lagoDiBraies: { nome: "lagoDiBraies", coordenadas: [46.696862, 12.0833146], imagem: null },
        innsbruck: { nome: "innsbruck", coordenadas: [47.26312602007974,11.400847434997559], imagem: null },
        lagoComTorre: { nome: "lagoComTorre", coordenadas: [46.809326, 10.5381904], imagem: null },
        landeck: { nome: "landeck", coordenadas: [47.1413887, 10.5462422], imagem: null },
        liechestein: { nome: "liechestein", coordenadas: [47.16730970131578,9.509010314941408], imagem: null },
        appenzel: { nome: "appenzel", coordenadas: [47.32881751198527, 9.410133361816408], imagem: null },
        zurique: { nome: "zurique", coordenadas: [47.36952443865407, 8.537750244140627], imagem: null },
        lucerna: { nome: "lucerna", coordenadas: [47.04977402806796, 8.30120086669922], imagem: null },
        interlaken: { nome: "interlaken", coordenadas: [46.684305180613265, 7.863464355468751], imagem: null },
        ginderwald: { nome: "ginderwald", coordenadas: [46.62368227263634, 8.037614822387697], imagem: null },
        furkapass: { nome: "furkapass", coordenadas: [46.53445089005077, 8.348622322082521], imagem: null },
        simplon: { nome: "simplon", coordenadas: [46.19516093350264, 8.057441711425783], imagem: null },
        domodossola: { nome: "domodossola", coordenadas: [46.11507421441447, 8.292360305786135], imagem: null },
        genebra: { nome: "genebra", coordenadas: [46.20293952440993, 6.144275665283204], imagem: null },
        lausanne: { nome: "lausanne", coordenadas: [46.52185670484264, 6.63402557373047], imagem: null },
        chamonix: { nome: "chamonix", coordenadas: [45.924138381648106, 6.872634887695313], imagem: null }

    };

    public static interrail = {
        // Interrail
        nuremberg: { nome: "nuremberg", coordenadas: [49.498639547249255, 11.077995300292969], imagem: null },
        innsbruck: { nome: "innsbruck", coordenadas: [47.26312602007974,11.400847434997559], imagem: null },
        salzburg: { nome: "salzburg", coordenadas: [47.822491299842525,13.051929473876955], imagem: null },
        badIschl: { nome: "badIschl", coordenadas: [47.71195616027106,13.627424240112306], imagem: null },
        halstatt: { nome: "halstatt", coordenadas: [47.56192518703646, 13.648989200592043], imagem: null },
        bled: { nome: "bled", coordenadas: [46.36835639784859, 14.109921455383303], imagem: null },
        lesce: { nome: "Lesce", coordenadas: [46.359160967697406,14.158952236175539], imagem: null },
        bohinksja: { nome: "bohinksja", coordenadas: [46.27169010191524,13.958473205566408], imagem: null },
        zagreb: { nome: "zagreb", coordenadas:   [45.80481138832745,15.965795516967775], imagem: null },
        split: { nome: "split", coordenadas: [43.504748527694005, 16.44312143325806], imagem: null },
        mostar: { nome: "mostar", coordenadas: [43.348478701958356, 17.814030647277836], imagem: null },
        sarajevo: { nome: "sarajevo", coordenadas: [43.86040095784028,18.39892387390137], imagem: null },
        belgrado: { nome: "belgrado", coordenadas: [44.7932720376283, 20.45341014862061], imagem: null },
        podgorica: { nome: "podgorica", coordenadas: [42.43090069157956, 19.26907539367676], imagem: null },
        kotor: { nome: "kotor", coordenadas: [42.425250327853234, 18.770334720611576], imagem: null },
        tirana: { nome: "tirana", coordenadas: [41.41280128616949, 19.720458984375004], imagem: null }

    }


    public static picos = {
        // Picos
        pelugano: { nome: "pelugano", coordenadas: [43.150409006254044, -5.58405876159668], imagem: null },
        riano: { nome: "riano", coordenadas: [42.97564820937357, -5.005602836608888], imagem: null },
        lagosCovadonga: { nome: "lagosCovadonga", coordenadas: [43.27186799803509, -4.982503652572633], imagem: null },
        covadonga: { nome: "covadonga", coordenadas: [43.308941276170124, -5.052680969238282], imagem: null },
        cangasOnis: { nome: "cangasOnis", coordenadas: [43.35339371637784, -5.12889862060547], imagem: null },
        fuenteDe: { nome: "fuenteDe", coordenadas: [43.1442991621164, -4.812194108963014], imagem: null },
        penaMea: { nome: "penaMea", coordenadas: [43.17497332107583, -5.561120510101319], imagem: null },
        potes: { nome: "potes", coordenadas: [43.15410339847369, -4.622840881347657], imagem: null },
        poncebos: { nome: "poncebos", coordenadas: [43.2578932655187, -4.830207824707032], imagem: null },
        bulnes: { nome: "bulnes", coordenadas: [43.2377622085257, -4.823212623596191], imagem: null },
        arenasCabrales: { nome: "arenasCabrales", coordenadas: [43.30288273739969, -4.817419052124024], imagem: null },
        cain: { nome: "cain", coordenadas: [43.21342368093264, -4.904651738452779], imagem: null },
        virarPaCovadonga: { nome: "virarPaCovadonga", coordenadas: [43.34690269242944, -5.086498260498048], imagem: null }

    }

    public static n2 = {

        chaves: { nome: "chaves", coordenadas: [41.74080206423521, -7.4715614318847665], imagem: null },
        vilaPoucaAguiar: { nome: "vilaPoucaAguiar", coordenadas: [41.500060316971584, -7.644081115722656], imagem: null },
        vilaReal: { nome: "vilaReal", coordenadas: [41.30199078094228, -7.74364471435547], imagem: null },
        santaMartaPenaguiao: { nome: "santaMartaPenaguiao", coordenadas: [41.20881585352228, -7.782268524169923], imagem: null },
        regua: { nome: "regua", coordenadas: [41.163438588019744, -7.787504196166993], imagem: null },
        alijo: { nome: "alijo", coordenadas: [41.27839102733173, -7.476410865783692], imagem: null },
        lamego: { nome: "lamego", coordenadas: [41.09644568807273, -7.80956268310547], imagem: null },
        castroDaire: { nome: "castroDaire", coordenadas: [40.901179506246045, -7.931377887725831], imagem: null },
        saoPedroDoSul: { nome: "saoPedroDoSul", coordenadas: [40.76121149036269, -8.06375026702881], imagem: null },
        trondela: { nome: "trondela", coordenadas: [40.5169149010123, -8.07997226715088], imagem: null },
        santaCombaDao: { nome: "santaCombaDao", coordenadas: [40.399305380255164, -8.12994718551636], imagem: null },
        penacova: { nome: "penacova", coordenadas: [40.2698993969633, -8.27974319458008], imagem: null },
        vilaNovaPoiares: { nome: "vilaNovaPoiares", coordenadas: [40.210597237041995, -8.25650453567505], imagem: null },
        lousa: { nome: "lousa", coordenadas: [40.111184037385435, -8.244391679763796], imagem: null },
        gois: { nome: "gois", coordenadas: [40.154744680166836, -8.109927177429201], imagem: null },
        pedrogaoGrande: { nome: "pedrogaoGrande", coordenadas: [39.91673105400229, -8.145589828491213], imagem: null },
        serta: { nome: "serta", coordenadas: [39.80125817800944, -8.095357418060305], imagem: null },
        vilaDeRei: { nome: "vilaDeRei", coordenadas: [39.675773872715915, -8.14432376080816], imagem: null },
        abrantes: { nome: "abrantes", coordenadas: [39.4623394274065, -8.197774887084963], imagem: null },
        ponteSor: { nome: "ponteSor", coordenadas: [39.25053371440517, -8.009033203125002], imagem: null },
        morargil: { nome: "morargil", coordenadas: [39.053043163905414, -8.171982765197756], imagem: null },
        mora: { nome: "mora", coordenadas: [38.94435704836275, -8.163099288940431], imagem: null },
        montemorNovo: { nome: "montemorNovo", coordenadas: [38.64875167493608, -8.212537765502931], imagem: null },
        alcacovas: { nome: "alcacovas", coordenadas: [38.39350706599351, -8.154430389404299], imagem: null },
        torrao: { nome: "torrao", coordenadas: [38.294214291579316, -8.223009109497072], imagem: null },
        ferreiraDoAlentejo: { nome: "ferreiraDoAlentejo", coordenadas: [38.058110766446816, -8.114132881164553], imagem: null },
        aljustrel: { nome: "aljustrel", coordenadas: [37.876716515979915, -8.16412925720215], imagem: null },
        castroVerde: { nome: "castroVerde", coordenadas: [37.70220065343897, -8.08492905290621], imagem: null },
        almodovar: { nome: "almodovar", coordenadas: [37.511308806889716, -8.0590295791626], imagem: null },
        saoBrasAplortel: { nome: "saoBrasAplortel", coordenadas: [37.15277477990532, -7.887840270996095], imagem: null },
        faro: { nome: "faro", coordenadas: [37.0259358178709, -7.932021617889405], imagem: null },
    }
}