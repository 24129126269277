<ul class="timeline">
    <li *ngFor="let travel of travels; let index = index">
        <div  [class]="index % 2 === 0 ? 'direction-r' : 'direction-l'">
            <div class="flag-wrapper">
                <span class="hexa"></span>
                <span class="flag dillan">
                   {{ travel.title }}
                </span>         
            </div>
            <div class="desc">
                <b>{{ travel.year}}</b>
                <br/>
                --
                <br/>
                {{ travel.desc }}
            </div>           
        </div>
    </li>
</ul>