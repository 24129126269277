import { Component, OnInit } from '@angular/core';
import { WorksService } from 'src/app/services/works.service';

@Component({
  selector: 'app-accommodation-prices',
  templateUrl: './accommodation-prices.component.html',
  styleUrls: ['./accommodation-prices.component.css']
})
export class AccommodationPricesComponent implements OnInit {

  works = [];

  constructor(private worksService: WorksService) { }

  ngOnInit(): void {

    this.works = this.worksService.getAccomodations().slice(0, 4);
  }

}
