<div class="container">

    <div class="header"></div>

    <div class="row d-flex justify-content-center text-center my-5">
        <div class="col-sm-7">
            <h2 class="py-3 bold">
                Preçário captação contéudo alojamentos
            </h2>
            <p class="fs-5 text-muted">
                Contéudos de boa qualidade são essenciais para atrair potenciais clientes, pois influenciam diretamente a
                sua decisão, transmitindo profissionalismo e aumentando a confiança.
            </p>
        </div>
    </div>

    <div class="row row-cols-1 row-cols-md-3 mb-3 text-center">

        <div class="col-md-3">
            <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-3">
                    <h4 class="my-0 fw-bold">Pack Fotografia</h4>
                </div>
                <div class="card-body">
                    <h1 class="card-title pricing-card-title">150€</h1>
                    <ul class="list-group list-group-flush mt-3 mb-4">
                        <li class="list-group-item">Fotografias no interior e exterior</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-3">
                    <h4 class="my-0 fw-bold">Pack Vídeo</h4>
                </div>
                <div class="card-body">
                    <h1 class="card-title pricing-card-title">200€</h1>
                    <ul class="list-group list-group-flush mt-3 mb-4">
                        <li class="list-group-item">Vídeo do alojamento até 2min</li>
                        <li class="list-group-item">Filmagens no interior e exterior </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card mb-4 rounded-3 shadow-sm border-dark">
                <div class="card-header py-3 border-dark bg-dark text-white">
                    <h4 class="my-0 fw-bold">Pack Fotografia + vídeo</h4>
                </div>
                <div class="card-body">
                    <h1 class="card-title pricing-card-title">250€</h1>
                    <ul class="list-group list-group-flush mt-3 mb-4">
                        <li class="list-group-item">Vídeo do alojamento até 2min</li>
                        <li class="list-group-item">Filmagens no interior e exterior </li>
                        <li class="list-group-item">Fotografias no interior e exterior</li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-3">
                    <h4 class="my-0 fw-bold">Pack Drone</h4>
                </div>
                <div class="card-body">
                    <h2 class="card-title pricing-card-title">200€</h2>
                    <ul class="list-group list-group-flush mt-3 mb-4">
                        <li class="list-group-item">Vídeo do alojamento até 2min</li>
                        <li class="list-group-item">Fotografias aéreas do alojamento</li>

                    </ul>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="card mb-4 rounded-3 shadow-sm">
                <div class="card-header py-3">
                    <h4 class="my-0 fw-bold">Pack completo</h4>
                </div>
                <div class="card-body">
                    <h1 class="card-title pricing-card-title">350€</h1>
                    <ul class="list-group list-group-flush mt-3 mb-4">
                        <li class="list-group-item">Vídeo do alojamento até 2min</li>
                        <li class="list-group-item">Filmagens de câmara do interior e exterior </li>
                        <li class="list-group-item">Filmagens aéreas </li>
                        <li class="list-group-item">Fotografias de máquina e drone do alojamento</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>


    <!--
    <div class="table-responsive my-4">
        <table class="table text-center">
            <thead>
                <tr>
                    <th style="width: 30%;"></th>
                    <th style="width: 15%;">Fotografias</th>
                    <th style="width: 15%;">Vídeo</th>
                    <th style="width: 15%;">Máquina</th>
                    <th style="width: 15%;">Drone</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th scope="row" class="text-start">Pack Fotografia</th>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td></td>    
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td></td>

                </tr>
                <tr>
                    <th scope="row" class="text-start">Pack Vídeo</th>
                    <td></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td></td>
                   
                </tr>
            </tbody>

            <tbody>
                <tr>
                    <th scope="row" class="text-start">Pack Fotografia + Vídeo</th>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td></td>
                   
                </tr>
                <tr>
                    <th scope="row" class="text-start">Pack Drone</th>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                   

                </tr>

                <tr>
                    <th scope="row" class="text-start">Pack Completo</th>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                    <td> <i class="bi bi-check-lg ps-3"></i></td>
                  

                </tr>

            </tbody>
        </table>
    </div>
     -->

    <h4 class="bold">Condições</h4>
    <hr />
    <ul class="mt-3 mb-5">
        <li>Em todos os packs, os primeiros 50km são gratuitos (a partir da Trofa). Acima desta distância é cobrado 0,36 cêntimos por Km.</li>
        <li>Os valores apresentados encontram-se isentos de IVA ao abrigo do artigo 53º do CIVA.</li>
        <li>O pagamento deve ser efetuado 50% na adjudicação e 50% no dia da entrega final.</li>
        <li>Prazo de entrega: A definir conforme necessidade do cliente.</li>
    </ul>


    <h4 class="bold">Últimos trabalhos</h4>
    <hr />

    <div class="container">
        <div class="adventures" >
            <div class="row">
                <div *ngFor="let work of works" class="col-lg-3 thumb p-1 m-0" data-aos="fade-up" data-aos-duration="1000">
                    <a [href]="'work/' + work.name">
                        <div class="item">
                            <img [src]="'assets/img/works/' + work.name + '/' + work.thumb" alt="" class="img-fluid m-0 p-0" />
                            <div class="overlay">
                                <div class="text">
                                    <span class="dillan">
                                        {{ work.title }}
                                    </span>
                                    <br/>
                                    <span class="type">
                                        <ng-container *ngFor="let item of work.type" >
                                            {{ item | translate  }}
                                        </ng-container>
                                    </span>
                                </div>
                              </div>
                        </div>  
                    </a>
                </div>
            </div>
        </div>
    </div>

</div>