import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import AOS from "aos";

@Component({
  selector: 'app-morocco',
  templateUrl: './morocco.component.html',
  styleUrls: ['./morocco.component.css']
})
export class MoroccoComponent implements OnInit {

  cities : string [] = [
    "Marrakesh",
    "Aït Benhaddou",
    "Uarzazate",
    "Tinghir",
    "Merzouga",
  ]

  constructor(private meta: Meta) {}

  ngOnInit(): void {
    AOS.init();

    this.meta.addTag({ property: 'og:title', content: 'Morocco' });
    this.meta.addTag({ property: 'og:description', content: 'Morocco photo collection' });
    this.meta.addTag({ property: 'og:image', content: 'https://nelsonrsousa.com/assets/img/travels/2022/morocco/morocco1.jpg' });

  }

}
