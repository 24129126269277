

<app-timeline [cities]="cities"></app-timeline>

<div class="container">

    <div class="travel" data-aos="fade-up" data-aos-duration="1000">


        <div class="row d-flex justify-content-center pb-5 mb-5">
            <div class="col-sm-10" >
                <div class="ratio ratio-16x9 mb-3">
                    <iframe class="border-radius" src="https://www.youtube.com/embed/LTvOo866UJ0" title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        <!-- 
        <div class="text-center pb-3">

            <div class="d-inline ">
                <span class="badge bg-dark text-center">2023</span>
            </div>
            <div class="d-inline flag">
                <img
                    src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/JO.svg" />
            </div>

           
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/DE.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/AT.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/SI.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/HR.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/BA.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/ME.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/AL.svg" />
            </div>
           
            <h1 class="text-center mt-3">Jordânia</h1>
        </div>
         -->

        <div class="row d-flex justify-content-center">
            
            <!-- 
            <div class="col-8">
                <p class="my-3 text-color">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
                </p>
            </div>
            -->

        </div>




        <div class="row">

            <div class="col-lg-7 ">
                <img src="assets/img/travels/2023/jordan/jordan1.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/jordan/jordan30.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/jordan/jordan2.jpg" alt="" class="img-fluid" />
            </div>


            <div class="col-lg-6">
                <img src="assets/img/travels/2023/jordan/jordan4.jpg" alt="" class="img-fluid" />
            </div>
<!--
 <div class="col-lg-4">
                <img src="assets/img/travels/2023/jordan/jordan3.jpg" alt="" class="img-fluid" />
            </div>
-->
           


        </div>


        <div class="row">

            <div class="col-lg-8">
                <img src="assets/img/travels/2023/jordan/jordan6.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/jordan/jordan7.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/jordan/jordan8.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-7">
                <img src="assets/img/travels/2023/jordan/jordan10.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/jordan/jordan5.jpg" alt="" class="img-fluid" />
            </div>


            <div class="col-lg-4">
                <img src="assets/img/travels/2023/jordan/jordan9.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/jordan/jordan11.jpg" alt="" class="img-fluid" />
            </div>


        </div>


        <div class="row vertical-photos">
            <div class="col-12">
                <img src="assets/img/travels/2023/jordan/jordan18.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/jordan/jordan19.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/jordan/jordan20.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/jordan/jordan17.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-8">
                <img src="assets/img/travels/2023/jordan/jordan16.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">
            <div class="col-12">
                <img src="assets/img/travels/2023/jordan/jordan21.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <div class="row vertical-photos">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/jordan/jordan23.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/jordan/jordan22.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">

            <div class="col-lg-3">
                <img src="assets/img/travels/2023/jordan/jordan24.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-9">
                <img src="assets/img/travels/2023/jordan/jordan25.jpg" alt="" class="img-fluid" />
            </div>

        </div>


        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/jordan/jordan26.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/jordan/jordan27.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/jordan/jordan28.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/jordan/jordan29.jpg" alt="" class="img-fluid" />
            </div>

        </div>

    </div>
</div>