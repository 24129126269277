
<app-timeline [cities]="cities">
</app-timeline>

<div class="container">
    <div class="masonry-container">
        <div  *ngFor="let item of images" class="masonry-item">
            <img [src]="this.mainUrl + item + '.jpg' " alt=""  />
        </div>
    </div>
</div>