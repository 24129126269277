
<!-- 
<div class="container-fluid">
    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div class="carousel-item active" data-bs-interval="5000">
            <img src="assets/img/travels/2022/picos/picos-18.jpg" class="d-block w-100" >
          </div>
          <div class="carousel-item" data-bs-interval="5000">
            <img src="assets/img/drone/drone-11.jpg" class="d-block w-100" >
          </div>
          <div class="carousel-item" data-bs-interval="5000">
            <img src="assets/img/travels/2023/interrail/bled-3.jpg" class="d-block w-100" >
          </div>
          <div class="carousel-item" data-bs-interval="5000">
            <img src="assets/img/travels/2023/norway/norway-4.jpg" class="d-block w-100" >
          </div>
          <div class="carousel-item" data-bs-interval="5000">
            <img src="assets/img/works/red-dragao/thumb.jpg" class="d-block w-100" >
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
</div>
-->


<div class="container">
  <video class="video" autoplay muted loop>
    <source src="assets/img/me/showreel.mp4" type="video/mp4">
    Your browser does not support HTML video.
  </video>
  
</div>

