import { Component, OnInit } from '@angular/core';
import AOS from "aos";


@Component({
  selector: 'app-picos',
  templateUrl: './picos.component.html',
  styleUrls: ['./picos.component.css']
})
export class PicosComponent implements OnInit {

  cities : string [] = [
    "Ojo de Buey",
    "Bulnes",
    "Ruta del Cares",
    "Covadonga",
    "Riaño"
  ]

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
