<div class="container">

    <div class="row d-flex justify-content-center mt-5">
        <div class="col-sm-10">
            <div class="ratio ratio-16x9 mb-3">
                <iframe class="border-radius" src="https://www.youtube.com/embed/Jn3xb_0paJo" title="YouTube video"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>
</div>