import { Component, ViewChild, ElementRef, Renderer2, OnInit, HostListener} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(private router: Router, private renderer: Renderer2, private elRef: ElementRef,private translateService: TranslateService, private cookieService: CookieService) {}
  
  menuOpen: boolean = false;

  currentLang = "";

  langs = ['en', 'pt'];

  menu = [
    {
      name: "MENU_ABOUT",
      url: "/about",
    },
    {
      name: "MENU_PHOTO",
      url: "/photo",
    },
    {
      name: "MENU_WORK",
      url: "/work",
    },
    //{
      //name: "Drone",
      //url: "/drone",
    //},
    {
      name: "MENU_TRAVEL",
      url: "/travel",
    },
    {
      name: "MENU_CONTACT",
      url: "/contacts",
    }
  ]

  showMenu: boolean;
  showPicture: boolean;

  ngOnInit(): void {

    let pagesToExcludeMenu = ["/links", "/worldmap", "/dolomites"];
   
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd)
        this.showMenu = !pagesToExcludeMenu.includes(this.router.url);
        this.showPicture = this.router.url == "/links";
    });


    let currentLanguage = this.cookieService.get("lang");

      if(currentLanguage) {

        this.currentLang = currentLanguage;
        this.translateService.use(currentLanguage);

      } else {

        this.currentLang = 'pt';
        this.translateService.use('pt');
      }
  }

  onChangeLanguage(langCode:string) {

    console.log(langCode);

    this.currentLang = langCode;
    this.translateService.use(langCode);
    this.cookieService.set("lang", langCode);
  }

  isMobile: boolean = window.innerWidth < 768;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = window.innerWidth < 768;
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

}
