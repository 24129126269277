import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prints',
  templateUrl: './prints.component.html',
  styleUrls: ['./prints.component.css']
})
export class PrintsComponent implements OnInit {

  constructor() { }

  price = 0.00;

  base = "assets/img/prints/"

  images =  [
    "arieiro.png",
    "bled.png",
    "desert.png",
    "riano.png",
    "sunset.png",
    "tower.png",
    "treasure.png",
    "wadi.png",
  ];

  selectedImage : string = this.base + this.images[0];

  ngOnInit(): void {
  }

  selectImage(image) {

    this.selectedImage = this.base + image;
    
  }

  selectSize(event) {

    let size = event.target.value;

    if(size == "30x45") {
      this.price = 17.99;
    } else if(size == "50x75") {
      this.price = 37.99;
    } else if(size == "60x90") {
      this.price = 59.99;
    } 

  }

  round(value ) {
    return value.toFixed(2);
  }

}
