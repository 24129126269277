<div class="dark" data-aos="fade-up">

    <div class="container">

        <div class="row d-flex justify-content-center mb-5 align-items-center">
            <div class="col-sm-3">
                <img src="assets/img/drone/drone-me.jpg" alt="" class="img-fluid border-radius" />
            </div>

            <div class="col-sm-5 px-5 text-color">
              
                <p>
                    Foi em Agosto de 2020 que adquiri o meu primeiro drone e foi um verdadeiro ponto de viragem na minha carreira como
                    fotógrafo e filmmaker. Desde
                    então, tenho viajado e capturado vistas incríveis e ângulos únicos que só o uso de um drone
                    poderia me permitir.
                    <br />
                    <br />
                    Em 2021, decidi dar o próximo passo e investir num drone de melhor qualidade para apronfundar ainda
                    mais minhas habilidades e oferecer aos meus clientes uma perspetiva diferente ainda mais profissional.
                    <br />
                    <br />
                    Num mercado cada vez mais competivivo, estou constantemente em busca de novos desafios e oportunidades para explorar o potencial criativo do uso
                    de drones.
                </p>
            </div>
        </div>
    </div>
</div>

<div class="container">

    <div class="row d-flex justify-content-center mt-5">
        <div class="col-sm-10">
            <div class="ratio ratio-16x9 mb-3">
                <iframe class="border-radius" src="https://www.youtube.com/embed/hysTc6wJwrA" title="YouTube video"
                    allowfullscreen></iframe>
            </div>
        </div>
    </div>

    <div class="travel" data-aos="fade-up" data-aos-duration="1000">
        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/drone/drone-5.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/drone/drone-11.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/drone/drone-9.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/drone/drone-13.jpg" alt="" class="img-fluid" />
            </div>
           
            <div class="col-lg-6">
                <img src="assets/img/drone/drone-12.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/drone/drone-6.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/drone/drone-2.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/drone/drone-3.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/drone/drone-4.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/drone/drone-8.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3">
                <img src="assets/img/drone/drone-7.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/drone/drone.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-3">
                <img src="assets/img/drone/drone-10.jpg" alt="" class="img-fluid" />
            </div>
        </div>
    </div>