import { Component, OnInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import AOS from "aos";
import { WorksService } from 'src/app/services/works.service';



@Component({
  selector: 'app-interrail',
  templateUrl: './interrail.component.html',
  styleUrls: ['./interrail.component.css']
})
export class InterrailComponent implements OnInit {

  images = [
    "hallstat-3",
    "hallstat-2",
    "hallstat-1",
    "eslovenia-1",
    "eslovenia-3",
    "bled-3",
    "bled-1",
    "bled-2",
    "krka-1",
    "krka-2",
    "trogir-1",
    "trogir-2",
    "mostar-2",
    "mostar-1",
    "portao",
    "minerete",
    "kotor-2",
    "kotor-3",
    "kotor-1"
  ];


  cities : string [] = [
    "Nuremberg",
    "Innsbruck",
    "Salzburg",
    "Hallstatt",
    "Bled",
    "Zagreb",
    "Split",
    "Mostar",
    "Sarajevo",
    "Belgrado",
    "Kotor",
    "Tirana"
  ];

  mainUrl: string = "assets/img/travels/2023/interrail/";

  constructor(public workService: WorksService, private el: ElementRef) { }

  ngOnInit(): void {
    AOS.init();

    /*
    const imgElements = this.el.nativeElement.querySelectorAll('img');
    imgElements.forEach((imgElement: HTMLElement) => {
      this.renderer.listen(imgElement, 'click', (event: Event) => {
        this.handleImageClick(event);
      });
    });
    */
  }

  handleImageClick(event: Event) {
    // Perform your desired action here
    this.workService.openImage(event)
  }

  ngAfterViewInit() {
   
  }
  
}
