import { Component, OnInit } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-alps',
  templateUrl: './alps.component.html',
  styleUrls: ['./alps.component.css']
})
export class AlpsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    AOS.init();
  }

}
