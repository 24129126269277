<div class="container">

    <div class="travel" data-aos="fade-up" data-aos-duration="1000">

       
        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-3.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-18.jpg" alt="" class="img-fluid" />
            </div>
        </div>

       

        <div class="row">
        

            <div class="col-lg-12">
                <img src="assets/img/travels/2023/alps/alps-2.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
          
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-52.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-4.jpg" alt="" class="img-fluid" />
            </div>

        </div>

      

        <div class="row">

            <div class="col-lg-7">
                <img src="assets/img/travels/2023/alps/alps-7.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/alps/alps-8.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">
            <div class="col-lg-3">
                <img src="assets/img/travels/2023/alps/alps-10.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/alps/alps-11.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-9.jpg" alt="" class="img-fluid" />
            </div>
        
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-12.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-13.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-3">
                <img src="assets/img/travels/2023/alps/alps-17.jpg" alt="" class="img-fluid" />
            </div>


            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-15.jpg" alt="" class="img-fluid" />
            </div>

            
            <div class="col-lg-3">
                <img src="assets/img/travels/2023/alps/alps-16.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-19.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-20.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row vertical-photos">

            <div class="col-lg-12">
                <img src="assets/img/travels/2023/alps/alps-21.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-22.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-24.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-23.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-25.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-29.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>


        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-27.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-26.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-28.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-3">
                <img src="assets/img/travels/2023/alps/alps-31.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-9">
                <img src="assets/img/travels/2023/alps/alps-32.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>



        <div class="row">

            <div class="col-lg-12">
                <img src="assets/img/travels/2023/alps/alps-33.jpg" alt="" class="img-fluid" />
            </div>

           
        </div>


        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-34.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-35.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-37.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/alps/alps-36.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-7">
                <img src="assets/img/travels/2023/alps/alps-38.jpg" alt="" class="img-fluid" />
            </div>
      
        </div>

        <div class="row">

            <div class="col-lg-8">
                <img src="assets/img/travels/2023/alps/alps-40.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-39.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-41.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-8">
                <img src="assets/img/travels/2023/alps/alps-43.jpg" alt="" class="img-fluid" />
            </div>

        </div>


        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-45.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-44.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-47.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-50.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-48.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-49.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-51.jpg" alt="" class="img-fluid" />
            </div>

        </div>


    </div>

</div>