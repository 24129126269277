<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="horizontal-timeline">
                <ul class="list-inline items">
                    <li *ngFor="let city of cities" class="list-inline-item items-list">
                        <div class="px-4">
                            <h6 class="pt-2">{{ city }}</h6>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>