
<footer class="footer">
    <div class="container text-center">

            <div class="d-flex justify-content-center">

            <p> {{ 'FOOTER_TEXT' | translate }} © {{ currentYear }}, Nelson Sousa </p>
       
          </div>
     
    </div>
</footer>