import { Component, OnInit } from '@angular/core';
import AOS from "aos";


@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.css']
})
export class BioComponent implements OnInit {


  photos = [
    "assets/img/me/me-2.jpg",
    "assets/img/me/me-3.jpg",
    "assets/img/me/me-4.jpg",
    "assets/img/me/me-5.jpg",
    "assets/img/travels/2023/norway/norway-48.jpg",
    "assets/img/me/me-6.jpg",
    "assets/img/me/me-7.jpg",
  ];

  constructor() { }

  ngOnInit(): void {
    AOS.init();

  }

}
