<div class="container" data-aos="fade-up" data-aos-duration="1000">
    <div class="row justify-content-center">
        <div class="col-sm-12">
            <img src="assets/img/banners/contactos.jpg" class="img-fluid banner" alt="">
        </div>
    </div>
    <div class="row d-flex align-items-center justify-content-center text-center">
        <div class="col-sm-5">
            <h2 class="font-weight-bold mb-4 title azalea">
                {{'CONTACT_TEXT' | translate }}
            </h2>
            <p>
                Nelson Sousa
                <br />
                (+351) 913 641 023
                <br />
                <a href="mailto:work@nelsonrsousa.com">
                    work@nelsonrsousa.com
                </a>
                <br />
                Porto
                <br/>

               
               
            </p>

            <div>
                <a class="p-2 text-dark text-decoration-none" href="#">
                    <i class="bi bi-instagram"></i>
                  </a>
                  
                  <a class="p-2 text-dark text-decoration-none" href="#">
                    <i class="bi bi-youtube"></i>
                  </a>
            
                  <a class="p-2 text-dark text-decoration-none" href="#">
                    <i class="bi bi-linkedin"></i>
                  </a>
            </div>

        </div>
    </div>
</div>