import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-timeline-page',
  templateUrl: './timeline-page.component.html',
  styleUrls: ['./timeline-page.component.css']
})
export class TimelinePageComponent implements OnInit {

  constructor() { }

  travels =  [
    {
      year: "Jun 2019",
      title: "Madrid",
      desc: "Das primeiras grandes viagens que fiz. Fizemos uma viagem de autocarro de 9 horas durante a noite e ficamos 4 dias em Madrid. Vimos praticamente os sítios mais turísticos de Madrid."
    },
    {
      year: "Out 2020",
      title: "N222",
      desc: "Antes de realizar o interrail, percorri a N222 em dois dias (Gaia - Vila Nova de Foz Côa). Dormimos na pousada da juventude de Alijó."
    },
    {
      year: "Out 2020",
      title: "Intrarail",
      desc: "A ideia inicial seria realizar um interrail, no entanto com a pandemia do COVID-19, tive de improvisar. Por isso decidi exlorar Portugal de comboio durante duas semanas e visitei localidades como Leiria, Lisboa, Almada, Setúbal, Évora, Beja, Portimão, Lagos, Faro, Vila Real de Santo António, Coimbra."
    },
    {
      year: "Set 2021",
      title: "N2 + Gibraltar + Sevilha",
      desc: "Percorremos a N2 desde Chaves até faro, num carro alugado (Ibiza do aço!). No fim, ainda demos um saltinho a Sevilha e Gibraltar"
    },
    {
      year: "Out 2021",
      title: "Madeira #1",
      desc: "Minha primeira ida à Madeira. Fiquei a dormir numa casinha simples na calheta. Fiz a levada do caldeirão caldeirão verde, vi o sunrise no pico do arieiro, apanhei o fanal com aquele nevoeiro místico, fiz meio trilho da ponta de São Lourenço, e mais alguns locais."
    },
    {
      year: "Fev 2022",
      title: "Suiça",
      desc: "3 dias com os meus dois melhores amigos em casa dos meus tios. Tivemos em algumas localidades ao longo do lago Léman. Ainda demos um salto a Chamonix."
    },
    {
      year: "Mar 2022",
      title: "Roma, Florença",
      desc: "Viagem integrada num grupo de jovens da paróquia. Fomos a Roma, ao Vaticano e a Florença."
    },
    {
      year: "Mar 2022",
      title: "Madeira #2",
      desc: "Segunda vez na Madeira. Voo cancelado dois dias devido a mau tempo na madeira. Foi especial pois apanhei o Pico Do Arieiro com neve, mas ainda assim não foi desta que fiz o trilho até ao Pico Ruivo. Fiz novamente o caldeirão verde, fiz a levada das 25 fontes e fui ao Fanal. Também tentei ir ver o nascer do sol à ponta de são lourenço mas sem sucesso devido ao mau tempo."
    },
    {
      year: "Abr 2022",
      title: "Picos #1",
      desc: "Viagem com malta da empresa. Não tivemos muita sorte com o tempo. Tentamos subir o Pico Gilbo, acampamos a meio e no dia a seguir estava a nevar. Tivemos de voltar para trás. Tambbém tentamos fazer o trilho da Ruta del Cares, mas devido a muita chuva, só fizemos 1/3. Subimos o teleférico em Fuente Dé, mas estava muita neve no topo. Fomos aos lagos de covadonga."
    },
    {
      year: "Ago 2022",
      title: "Alqueva",
      desc: "Roadtrip de 3 dias, com uma tenda de tejadilho na carrinha por Reguengos de Monzaraz."
    },
    {
      year: "Out 2022",
      title: "Picos #2",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
    {
      year: "Nov 2022",
      title: "Marrocos",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
    {
      year: "Dez 2022",
      title: "Roménia",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
    {
      year: "Jan 2023",
      title: "Jordânia",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
    {
      year: "Abr 2023",
      title: "Interrail",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
    {
      year: "Jun 2023",
      title: "Madeira #3",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
    {
      year: "Ago 2023",
      title: "Costa sul Espanha",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
    {
      year: "Set 2023",
      title: "Lofoten + Senja",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
    {
      year: "Dez 2023",
      title: "Dolomites",
      desc: "Lorem ipsum Nisi labore aute do aute culpa magna nulla voluptate exercitation deserunt proident."
    },
   
  ];

  ngOnInit(): void {


    this.travels.reverse();
  }

}
