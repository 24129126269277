import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import AOS from "aos";
import { WorksService } from 'src/app/services/works.service';


@Component({
  selector: 'app-work-detail',
  templateUrl: './work-detail.component.html',
  styleUrls: ['./work-detail.component.css']
})
export class WorkDetailComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private worksService: WorksService) { }
  
  selectedWork: any;

  ngOnInit(): void {

    AOS.init();

    var name = this.activatedRoute.snapshot.paramMap.get('name');

    this.selectedWork = this.worksService.findWork(name);

    if (this.selectedWork == undefined) {
      debugger;
      this.router.navigate(['/'])
    }

  }

}
