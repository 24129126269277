import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'

export enum WORK_TYPES {
  CORPORATE = "corporate",
  EVENTS = "events",
  ACCOMMODATION = "accomodation",
  MUSIC = "music",
}

@Injectable({
  providedIn: 'root'
})

export class WorksService {

  youtubeBase = "https://www.youtube.com/embed/"

  works: any[] = [
    {
      name: "gravel-birds-2024",
      title: "Gravel Birds",
      desc: "",
      thumb: "thumb.jpg",
      videoUrl: [this.youtubeBase + "sKE6HEvxG0M", this.youtubeBase + "u0l254qbbcU"],
      segment: WORK_TYPES.EVENTS,
      type: ['VIDEO']
    },
    {
      name: "refugio-das-oliveiras",
      title: "Refugio das Oliveiras",
      desc: "",
      thumb: "refugio-das-oliveiras-17.jpg",
      videoUrl: [this.youtubeBase + "CVa2clVrtU8"],
      segment: WORK_TYPES.ACCOMMODATION,
      type: ['PHOTO', 'VIDEO']  
    },
    {
      name: "alves-2",
      title: "ALVES (PT) - Live @ TECHNO BUNKER, Santo Tirso, Portugal / DJ Mix",
      desc: "",
      thumb: "thumb.jpg",
      videoUrl: [this.youtubeBase + "K2mLdijV2cI"],
      segment: WORK_TYPES.MUSIC,
      type: ['VIDEO']  
    },
  {
      name: "refugio-carrascal",
      title: "Refúgio Carrascal",
      desc: "",
      thumb: "carrascal-6.jpg",
      videoUrl: null,
      segment: WORK_TYPES.ACCOMMODATION,
      type: ['PHOTO']  
    },
 
    {
      name: "raposa-lodges",
      title: "Raposa Lodges",
      desc: "",
      thumb: "raposa-lodges-22.jpg",
      videoUrl: null,
      segment: WORK_TYPES.ACCOMMODATION,
      type: ['PHOTO']  
    },
    {
      name: "borealis",
      title: "Borealis - Serra de Sicó e Aldeias de Xisto",
      desc: "",
      thumb: "thumb.jpg",
      videoUrl: [this.youtubeBase + "Y5ocBKW_4PA"],
      segment: WORK_TYPES.EVENTS,
      type: ['VIDEO']  
    },
    {
      name: "casa-das-flores",
      title: "Casa das Flores",
      desc: "",
      thumb: "casa-das-flores-2.jpg",
      videoUrl: null,
      segment: WORK_TYPES.ACCOMMODATION,
      type: ['PHOTO']  
    },
    {
      name: "red-luz",
      title: "Red - Substituição de Relvado Estádio da Luz",
      desc: "",
      thumb: "thumb.jpg",
      videoUrl: [this.youtubeBase + "_6VYf7dBSQI"],
      segment: WORK_TYPES.CORPORATE,
      type: ['VIDEO']  
    },
    {
      name: "red-dragao",
      title: "Red - Substituição de Relvado Estádio do Dragão",
      desc: "",
      thumb: "thumb.jpg",
      videoUrl: [this.youtubeBase + "Snu0PbjwvaA"],
      segment: WORK_TYPES.CORPORATE,
      type: ['VIDEO']  
    },
    {
      name: "vn-dressage-cup",
      title: "VN Dressage Cup",
      desc: "",
      frames: [
        "frame1.jpg",
        "frame2.jpg",
        "frame3.jpg",
        "frame4.jpg",
        "frame5.jpg",
        "frame6.jpg",
        "frame7.jpg",
        "frame8.jpg",
        "frame9.jpg",
        "frame10.jpg",
        "frame10.jpg",
      ],
      thumb: "frame4.jpg",
      videoUrl: [this.youtubeBase + "LQ7c_NJn0dI"],
      segment: WORK_TYPES.EVENTS,
      type: ['VIDEO']  
    },
    {
      name: "zino-palace",
      title: "1905 Zino's Palace Madeira",
      desc: "",
      images: [],
      thumb: "zino-14.jpg",
      videoUrl: null,   
      segment: WORK_TYPES.ACCOMMODATION,
      type: ['PHOTO']  
    },
    {
      name: "alves",
      title: "EXCLUSIVE: ALVES live dj mix for Techno Station",
      desc: "Set de 1h15 gravado com camera e drone",
      images: [],
      thumb: "thumb.jpg",
      videoUrl: this.youtubeBase + "y5FxofzU5EU",
      segment: WORK_TYPES.MUSIC,
      type: ['VIDEO']  

    },
    {
      name: "tripstomorocco",
      title: "Trips to Morocco - Drone Camp",
      desc: "Drone Camp é um vídeo pertencente a uma série de vídeos realizados para a Trips To Morocco, uma empresa de turismo marroquina com a qual tive o prazer de trabalhar. Mais detalhes sobre o trabalho <a target='_blank' href='https://www.miguelnunesdp.com/mediakit'>aqui</a> <br/> <br/> Filmagem: Nelson Sousa <br/> Edição: Miguel Nunes",
      images: [],
      thumb: "thumb.jpg",
      videoUrl: [this.youtubeBase + "YYnjN8H8znw"],
      segment: WORK_TYPES.ACCOMMODATION,
      type: ['VIDEO']  
    },
    {
      name: "saosilvestre",
      title: "São Silvestre 2022 @ ONE MORE",
      desc: "A corrida de São Silvestre é uma corrida tipicamente de 10km's e que ocorre em várias zonas do país no mês de dezembro e janeiro. <br/>O objetivo do vídeo, para além de mostrar toda a corrida é também evidenciar a presença da ONE MORE (patrocinador da corrida) na organização do evento. <br/><br/> Filmagem e edição: Nelson Sousa",
      frames: [
        "frame1.jpg",
        "frame2.jpg",
        "frame3.jpg",
        "frame4.jpg",
        "frame5.jpg",
        "frame6.jpg",
        "frame7.jpg",
        "frame8.jpg",
        "frame9.jpg",
        "frame10.jpg",
      ],
      thumb: "frame5.jpg",
      videoUrl: [this.youtubeBase + "JH5MWVfNPMI"],
      segment: WORK_TYPES.EVENTS,
      type: ['VIDEO']  
    },
    {
      name: "red-olival",
      title: "Red - Substituição de Relvado Olival",
      desc: "A RED é uma empresa com um conjunto de serviços, desde Projectos Paisagísticos e de Redes de Rega, Construção e Manutenção de Espaços Verdes e Relvados Desportivos e Comercialização de Produtos e Equipamentos especializados para manutenção <br/> O objetivo do vídeo é mostrar como é feito o processo de substituição de um relvado desportivo. <br/> <br/> Filmado por: Nelson Sousa <br/> Editado por: Nelson Sousa",
      images: [],
      thumb: "thumb.jpg",
      videoUrl: [this.youtubeBase + "xqOPZYlh5fM"],
      segment: WORK_TYPES.CORPORATE,
      type: ['VIDEO']  
    }
  ]

  constructor() { }

  getAll() {
    return this.works;
  }


  getByType(type: string) {
    return this.works.filter(work => work.segment == type);
  }

  findWork(name) {

    return this.works.find(work => work.name == name);

  }

  getAccomodations() {
    return this.works.filter(work => work.segment == WORK_TYPES.ACCOMMODATION);
  }

  openImage(event: any) {
    Swal.fire({
      html: "<img class='pop-up-img' src='" + event.target.src + "'/>",
      width: 'auto',
      showConfirmButton: false,
    })
  }
 
}
