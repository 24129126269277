import { Component, OnInit } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-photo',
  templateUrl: './photo.component.html',
  styleUrls: ['./photo.component.css']
})
export class PhotoComponent implements OnInit {
  
  photos = [
    "assets/img/travels/2023/norway/norway-56.jpg", // verde
    "assets/img/drone/drone-11.jpg", // sunset 
    "assets/img/travels/2022/madeira/madeira-11.jpg", // verde 
    "assets/img/travels/2022/morocco/morocco4.jpg", // laranja
    "assets/img/travels/2022/picos/picos.jpg", // verde 

    "assets/img/travels/2022/morocco/morocco7.jpg", // azul
    "assets/img/travels/2023/norway/norway-23.jpg", // verde
    "assets/img/travels/2022/morocco/morocco3.jpg", // laranja
    "assets/img/travels/2022/picos/picos-18.jpg", // verde 
    "assets/img/showcase/compress/carrinha.jpg", // roxo

    "assets/img/travels/2023/interrail/eslovenia-3.jpg", // verde
    "assets/img/travels/2023/jordan/jordan30.jpg", // sunset
    "assets/img/travels/2022/morocco/morocco8.jpg" ,// verde
    "assets/img/travels/2023/jordan/jordan29.jpg", // laranja 
    "assets/img/travels/2022/picos/picos-9.jpg", // verde 

    "assets/img/travels/2023/jordan/jordan22.jpg", // laranja
    "assets/img/travels/2023/interrail/krka-2.jpg", // verde 
    "assets/img/travels/2023/interrail/minerete.jpg", // branco 
    "assets/img/travels/2022/picos/picos-10.jpg", // verde 
    "assets/img/travels/2022/madeira/madeira-5.jpg", // sunset

    "assets/img/other/estradas.jpg", // verde
    "assets/img/other/ursa.jpg", // laranja
    "assets/img/other/queimadas.jpg", // verde
    "assets/img/other/reta.jpg", // laranja
    "assets/img/other/riano.jpg", // azul

    "assets/img/travels/2022/morocco/morocco20.jpg", // laranja
    "assets/img/travels/2023/interrail/thumb.jpg", // azul 
    "assets/img/travels/2023/jordan/jordan17.jpg", // laranja  
    "assets/img/travels/2022/picos/picos-13.jpg", // verde 
    "assets/img/travels/2022/morocco/morocco1.jpg", // laranja
    
    "assets/img/showcase/compress/caldeirao2.jpg", // verde
    "assets/img/travels/2022/morocco/morocco1.jpg", // laranja
    "assets/img/travels/2023/norway/norway-46.jpg", //azul
    "assets/img/travels/2022/morocco/morocco13.jpg", // laranja
    "assets/img/travels/2023/norway/norway-4.jpg",  // branco

    "assets/img/other/gilbo-noite.jpg", // preto
    "assets/img/other/lourenco.jpg", // castanho
    "assets/img/other/lousa.jpg", // sunset
    "assets/img/other/piodao.jpg", // verde
    "assets/img/other/manteigas.jpg", // laranja

    "assets/img/travels/2023/norway/norway-30.jpg" ,// verde 
    "assets/img/travels/2023/jordan/jordan27.jpg", // laranja 
    "assets/img/travels/2022/madeira/2023/madeira.jpg", // verde
    "assets/img/travels/2023/norway/norway-8.jpg", // azul 
    "assets/img/travels/2023/norway/norway-43.jpg", // cinza/verde

  ];



  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
