<div class="container">

    <div class="travel" data-aos="fade-up" data-aos-duration="1000">

        <div class="row justify-content-center">
            <div class="col-sm-10 mb-5">
                <div class="ratio ratio-16x9 mb-3 mb-5">
                    <iframe class="border-radius" [src]="'https://www.youtube.com/embed/7sg3rGioKLc' | safe"
                        title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-42.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-2.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/norway/norway-4.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/travels/2023/norway/norway-3.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/travels/2023/norway/norway-8.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/norway/norway-9.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-6.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-7.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/travels/2023/norway/norway-10.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/travels/2023/norway/norway-11.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/travels/2023/norway/norway-14.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/norway/norway-13.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-12.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-15.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/norway/norway-17.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/travels/2023/norway/norway-18.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/travels/2023/norway/norway-22.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/norway/norway-21.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img src="assets/img/travels/2023/norway/norway-23.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/norway/norway-26.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/travels/2023/norway/norway-25.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/travels/2023/norway/norway-27.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/travels/2023/norway/norway-30.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-5">
                <img src="assets/img/travels/2023/norway/norway-28.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img src="assets/img/travels/2023/norway/norway-29.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <div class="row">
            <div class="col-lg-3">
                <img src="assets/img/travels/2023/norway/norway-34.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-9">
                <img src="assets/img/travels/2023/norway/norway-35.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/norway/norway-36.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/travels/2023/norway/norway-39.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-5">
                <img src="assets/img/travels/2023/norway/norway-37.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img src="assets/img/travels/2023/norway/norway-38.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-40.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-41.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-5">
                <img src="assets/img/travels/2023/norway/norway-44.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img src="assets/img/travels/2023/norway/norway-43.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/travels/2023/norway/norway-45.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/travels/2023/norway/norway-46.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/travels/2023/norway/norway-48.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-8">
                <img src="assets/img/travels/2023/norway/norway-47.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-50.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/norway/norway-49.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img src="assets/img/travels/2023/norway/norway-51.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/travels/2023/norway/norway-52.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/norway/norway-53.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-5">
                <img src="assets/img/travels/2023/norway/norway-55.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img src="assets/img/travels/2023/norway/norway-56.jpg" alt="" class="img-fluid" />
            </div>
        </div>

    </div>
</div>