<div class="container">
        <div class="row d-flex">

            <div class="col-lg-7">

                <h1 class="dillan">Travel print</h1>

                <hr>

                <p>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>

                <ng-container *ngFor="let item of images">
                    <img (click)="selectImage(item)" class="cursor-pointer" height="90" [src]="this.base + item" alt="">
                </ng-container>

                <h3 class="bold my-4">
                    {{ this.round(this.price) }}€
                </h3>

                <select (change)="selectSize($event)" class="form-select form-select-lg my-5" aria-label=".form-select-lg example">
                    <option selected>Tamanho</option>
                    <option value="30x45">30x45</option>
                    <option value="50x75">50x75</option>
                    <option value="60x90">60x90</option>
                  </select>   

                  <button type="button p-4" class="btn btn-dark">Adicionar</button>
              
            </div>

            <div class="col-lg-5">         
                <img [src]="selectedImage" alt="" class="img-fluid" />
            </div>

        </div>

</div>