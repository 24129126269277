import { Component, OnInit, Renderer2, ElementRef } from '@angular/core';
import AOS from "aos";
import { WorksService } from 'src/app/services/works.service';


@Component({
  selector: 'app-jordan',
  templateUrl: './jordan.component.html',
  styleUrls: ['./jordan.component.css']
})
export class JordanComponent implements OnInit {

  constructor(public workService: WorksService, private renderer: Renderer2, private el: ElementRef) { }

  cities : string [] = [
    "Madeba",
    "Jerash",
    "Amman",
    "Petra",
    "Wadi Rum",
    "Aqaba"
  ]

  ngOnInit(): void {
    AOS.init();

    /*
    const imgElements = this.el.nativeElement.querySelectorAll('img');
    imgElements.forEach((imgElement: HTMLElement) => {
      this.renderer.listen(imgElement, 'click', (event: Event) => {
        this.handleImageClick(event);
      });
    });
    */
    
  }

  handleImageClick(event: Event) {
    // Perform your desired action here
    this.workService.openImage(event)
  }

}
