import { Component, OnInit } from '@angular/core';
import { WorksService } from 'src/app/services/works.service';
import AOS from "aos";


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor(private worksService : WorksService) { }

  ngOnInit(): void {
    AOS.init();
  }

}
