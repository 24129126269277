<div class="container">
    <div class="showcase" data-aos="fade-up" data-aos-duration="1000">
        <div class="row row-cols-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-5">
            <div *ngFor="let photo of photos" class="col p-1 m-0">
                <div class="square-image-wrapper" >
                    <img [src]="photo" alt="" class="square-image" />
                </div>
            </div>
        </div>
    </div>
</div>