<div class="container">

    <ul class="nav justify-content-center types my-5">
        <li *ngFor="let item of types" class="nav-item">
          <a class="btn btn-outline-dark mx-2 px-5" [href]="'work/type/' + item">{{ item | translate }}</a>
        </li>
      </ul>

    <div class="adventures" >
        <div class="row">
            <div *ngFor="let work of works" class="col-lg-4 thumb p-1 m-0" data-aos="fade-up" data-aos-duration="1000">
                <a [href]="'work/' + work.name">
                    <div class="item">
                        <img [src]="'assets/img/works/' + work.name + '/' + work.thumb" alt="" class="img-fluid m-0 p-0" />
                        <div class="overlay">
                            <div class="text">
                                <span class="dillan">
                                    {{ work.title }}
                                </span>
                                <br/>
                                <span class="type">
                                    <ng-container *ngFor="let item of work.type" >
                                        {{ item | translate  }}
                                    </ng-container>
                                </span>
                            </div>
                          </div>
                    </div>  
                </a>
            </div>
        </div>
    </div>
</div>