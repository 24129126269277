import { Component, OnInit } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-norway',
  templateUrl: './norway.component.html',
  styleUrls: ['./norway.component.css']
})
export class NorwayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
