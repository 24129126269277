<div class="container">
    <div class="adventures" >
        <div class="row p-0 m-0">
            <div *ngFor="let item of travels" [ngClass]="'col-lg-' + item.col + ' thumb p-1 m-0' " data-aos="fade-up" data-aos-duration="1000" >
                <a [href]="item.url">
                    <img [src]="item.img" alt="" class="img-fluid p-0 m-0" />
                    <div class="centered">{{ item.name | translate }}</div>
                </a>
            </div>
        </div>
    </div>
</div>