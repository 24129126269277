import { Directive, ElementRef, AfterViewInit } from '@angular/core';


@Directive({
  selector: '[appImageTitle]'
})
export class ImageTitleDirective {

 constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    const imgElement = this.elementRef.nativeElement as HTMLImageElement;


    let array = imgElement.src.split("/")
    const lastElement = array[array.length - 1];

    const title = lastElement;
    imgElement.title = title;
  }

}
